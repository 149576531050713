import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const Miraku = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/miraku/miraku-1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/miraku/miraku-2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/miraku/miraku-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "",
        "",
        "",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Miraku" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-24 mr-5">
                            <StaticImage src="../../images/brand/brand-3.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold">Miraku</h2>
                        <p className="font-light text-gray-600">12pm – 2.30pm / 6pm – 10pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 04-229 8702 / 017-214 2382</p>
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">
                    <div className="pt-10 pb-20 xl:px-16 w-full mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">               
                            Miraku, simply means a great place for customers to enjoy great food. It is a fine dining restaurant designed to provide the art of Japanese dining to those who desire to experience gourmet food. Established under the Texchem Group of Companies, Miraku aims to bring the best of Japanese culinary to Malaysia. Miraku also aspires to deliver the essence of Japanese cooking which places top priority in the selection of quality and fresh produce. Armed with the best and experienced chefs directly brought in from Japan, Miraku is marked to satisfy any food connoisseur's taste buds. On top of it, their exceptionally professional and personalized service will make dining in Miraku an unforgettable experience.
                        </p>
                        <div className="w-full mx-auto relative mt-12">
                            <StaticImage src={`../../images/promotion/promotion-8-miraku.jpeg`} {...imageProps}/>
                        </div>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default Miraku
